import Link from 'components/Globals/Base/Link';
import { NextCustomImage as NextImage } from 'components/Image';
import BylineList from 'components/Byline';
import Heading from 'styles/typography/Heading';
import { ArticlePreview } from 'interfaces/content/articles/Articles';
import { EventData } from 'services/Gtm/entities';
import React, { ForwardedRef, useMemo } from 'react';
import { FontFamily, ResponsiveStyling } from 'styles/theme/types';
import rawHtml from 'utils/miscUtils/rawHtml';
import { ARTICLE_CARD_TEST_IDS } from 'constants/testsIds/articleComponents';
import * as S from './ArticleCardStyles';
import {
  ArticleCardFieldsVariant,
  ArticleCardLayoutVariant,
  CustomFontSizes,
  getArticleCardFields,
  predefinedTitleFontFamily,
  predefinedTitleSize,
} from './articleCardHelpers';

interface Props {
  article: ArticlePreview;
  changesDirection?: boolean;
  layoutVariant?: ArticleCardLayoutVariant;
  dataVariant?: ArticleCardFieldsVariant;
  className?: string;
  trackingData: EventData;
  titleSize?: ResponsiveStyling<keyof CustomFontSizes>;
  titleFontFamily?: FontFamily;
  eyebrowType?: 'brandAccentPrimary' | 'red';
  priority?: boolean;
  dataCy?: string;
  index?: number;
}

const ArticleCard = (props: Props, ref: ForwardedRef<HTMLDivElement> | null) => {
  const {
    article: articleData,
    trackingData,
    layoutVariant = 'card-md',
    dataVariant,
    changesDirection = false,
    className = '',
    titleFontFamily,
    titleSize,
    eyebrowType = 'brandAccentPrimary',
    priority = false,
    dataCy = '',
    index,
  } = props;
  const headingAs = layoutVariant === 'hero' ? 'h2' : 'h3';
  const article = useMemo(() => getArticleCardFields(articleData, dataVariant), [articleData, dataVariant]);
  const dateUpdated = article.dateUpdatedGMTFormatted || article.dateUpdatedFormatted;
  const datePublished = article.dateGMTFormatted || article.dateFormatted;

  const sectionsRedesign = process.env.ENABLE_SECTION_PAGE_REDESIGN;

  const cardContent = (
    <>
      {!!article.image?.mediaItemUrl && !index && (
        <Link
          href={article.titleLink}
          type='image'
          className='card-image'
          trackingData={
            trackingData && {
              ...trackingData,
              eventLabel: `image click : ${trackingData.eventLabel || article.titleLink}`,
            }
          }
          data-cy={ARTICLE_CARD_TEST_IDS.IMAGE_LINK}
        >
          <NextImage
            width={300}
            height={300}
            src={article.image?.mediaItemUrl}
            alt={article.image?.altText ? article.image?.altText : article.title}
            className='image-link'
            priority={priority}
            data-cy={ARTICLE_CARD_TEST_IDS.IMAGE}
          />
        </Link>
      )}

      {index && <S.Index> {index} </S.Index>}

      <div
        data-cy={ARTICLE_CARD_TEST_IDS.CARD_WRAPPER}
        className={`card-wrapper ${index ? 'index' : ''}`}
      >
        {article.eyebrowSectionLabel &&
          article.eyebrowSectionLink &&
          article.eyebrowSectionLabel !== 'No Primary Section Found' &&
          !sectionsRedesign && (
          <Link
            href={article.eyebrowSectionLink}
            type={eyebrowType}
            className='card-eyebrow'
            ariaLabel={`Go to ${article.eyebrowSectionLabel}`}
            trackingData={
              trackingData && {
                ...trackingData,
                eventCategory: 'navigation',
                eventLabel: `category click : ${article.eyebrowSectionLabel}`,
              }
            }
            data-cy={ARTICLE_CARD_TEST_IDS.EYEBROW}
          >
            {article.eyebrowSectionLabel}
          </Link>
        )}

        <Heading
          as={headingAs}
          $fontFamily={titleFontFamily || predefinedTitleFontFamily(layoutVariant, changesDirection)}
          $size={titleSize || predefinedTitleSize(layoutVariant)}
          className='card-title'
          data-cy={ARTICLE_CARD_TEST_IDS.TITLE}
        >
          <Link
            href={article.titleLink}
            className='title-link'
            type='primary'
            trackingData={
              trackingData && {
                ...trackingData,
                eventCategory: 'navigation',
                eventLabel: `title click : ${trackingData.eventLabel || article.title}`,
              }
            }
          >
            {article.title}
          </Link>
        </Heading>

        {article.excerpt && (
          <S.Excerpt
            className={`${className} ${!sectionsRedesign ? 'excerpt' : 'sectionsRedesign'}`}
            data-cy={ARTICLE_CARD_TEST_IDS.EXCERPT}
          >
            {rawHtml({ html: article.excerpt })}
          </S.Excerpt>
        )}

        {article.authors && article.authors.length > 0 && (
          <BylineList
            className={`card-authors ${className === 'sections-small-card' ? 'sections-small-card' : ''}`}
            bylineList={article.authors}
            isArticle
            dataCy={ARTICLE_CARD_TEST_IDS.BY_LINE}
          />
        )}

        {(dateUpdated || datePublished) && (
          <span
            className='card-date'
            data-cy={ARTICLE_CARD_TEST_IDS.DATE}
          >
            {dateUpdated || datePublished}
          </span>
        )}
      </div>
    </>
  );

  return (
    <>
      {layoutVariant === 'card-xxs' && (
        <S.CardXXS
          className={className}
          data-cy={dataCy || ARTICLE_CARD_TEST_IDS.WRAPPER}
          ref={ref}
        >
          {cardContent}
        </S.CardXXS>
      )}
      {layoutVariant === 'card-xs' && (
        <S.CardXS
          className={className}
          data-cy={dataCy || ARTICLE_CARD_TEST_IDS.WRAPPER}
          ref={ref}
        >
          {cardContent}
        </S.CardXS>
      )}
      {layoutVariant === 'card-sm' && (
        <S.CardSm
          className={className}
          data-cy={dataCy || ARTICLE_CARD_TEST_IDS.WRAPPER}
          ref={ref}
          $changesDirection={changesDirection}
        >
          {cardContent}
        </S.CardSm>
      )}
      {layoutVariant === 'card-simple' && (
        <S.CardSimple
          className={className}
          data-cy={dataCy || ARTICLE_CARD_TEST_IDS.WRAPPER}
          ref={ref}
        >
          {cardContent}
        </S.CardSimple>
      )}
      {layoutVariant === 'card-md' && (
        <S.CardMd
          className={className}
          data-cy={dataCy || ARTICLE_CARD_TEST_IDS.WRAPPER}
          ref={ref}
          $changesDirection={changesDirection}
        >
          {cardContent}
        </S.CardMd>
      )}
      {layoutVariant === 'card-lg' && (
        <S.CardLg
          className={className}
          data-cy={dataCy || ARTICLE_CARD_TEST_IDS.WRAPPER}
          ref={ref}
        >
          {cardContent}
        </S.CardLg>
      )}
      {layoutVariant === 'card-xl' && (
        <S.CardXl
          className={className}
          data-cy={dataCy || ARTICLE_CARD_TEST_IDS.WRAPPER}
          ref={ref}
        >
          {cardContent}
        </S.CardXl>
      )}
      {layoutVariant === 'hero' && (
        <S.CardHero
          className={className}
          data-cy={dataCy || ARTICLE_CARD_TEST_IDS.WRAPPER}
          ref={ref}
        >
          {cardContent}
        </S.CardHero>
      )}
    </>
  );
};

export default React.memo(React.forwardRef(ArticleCard));
