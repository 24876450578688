import styled, { css } from 'styled-components';
import { bkptVal, breakpoint, font } from 'styles/globals';
import { getBodyStyle, getColor, Theme } from 'styles/theme';
import { imageAndTitleLinkHover } from 'styles/imageAndTitleLinkHover';

const hasExcerptStyles = css`
  .card-title {
    margin-bottom: 8px;
    font-size: 20px;
  }
`;

export const Excerpt = styled.div<{ theme: Theme }>`
  margin-bottom: 8px;
  letter-spacing: 0;
  font-style: normal;
  display: -webkit-box;
  ${getBodyStyle('graphikCompact', { default: 'default' }, 'regular')};
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.sectionsRedesign {
    -webkit-line-clamp: 2;
    margin-bottom: 16px;
    letter-spacing: 0.5px;
  }

  &.sections-small-card {
    display: none;
  }
`;

export const Index = styled.div<{ theme: Theme }>`
  font-family: ${font.saolText};
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  color: ${getColor('textSecondary')};
  margin-top: -2px;
`;

export const Card = styled.div<{
  theme: Theme;
}>`
  container: cardContainer/inline-size;
  ${imageAndTitleLinkHover}
  width: 100%;
  display: flex;

  .index {
    padding-bottom: 16px;
    border-bottom: 1px solid ${getColor('borderSecondary')};
    width: 100%;
  }

  .card-eyebrow {
    font-family: ${font.graphikCond};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 150%;
    text-transform: uppercase;
    position: relative;

    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 14px;
    }
  }

  &.compact .card-title {
    margin-bottom: 8px;
  }

  .card-authors {
    margin-top: auto;
    line-height: 120%;
  }

  .sections-small-card {
    margin-top: 12px;
  }

  .card-date {
    ${getBodyStyle('graphikCond', { default: 'small' }, 'light')};
    color: ${getColor('textSecondary')};
    letter-spacing: 1.17px;
    margin-top: 2px;
  }

  .card-wrapper {
    display: flex;
    flex-direction: column;
  }

  .card-image {
    img {
      object-fit: cover;
    }
  }
`;

export const CardXXS = styled(Card)<{
  theme: Theme;
}>`
  flex-direction: row;
  gap: 16px;

  .card-image {
    flex: 0 0 auto;

    img {
      max-width: 164px; 
      height: auto;
    }
  }

  .card-eyebrow,
  .card-title {
    margin-bottom: 8px;
  }
`;

export const CardXS = styled(Card)<{
  theme: Theme;
}>`
  flex-direction: row;
  gap: 16px;

  .card-image {
    flex: 0 0 40%;
    img {
      aspect-ratio: 3/2;
    }

    @media (${breakpoint.mdMin}) {
      flex: 0 0 30%;
    }
  }

  .card-eyebrow,
  .card-title {
    margin-bottom: 8px;
  }
`;

export const CardSm = styled(Card)<{
  theme: Theme;
  $changesDirection?: boolean;
}>`
  flex-direction: row;
  gap: 16px;

  .card-image {
    flex: 0 0 50%;
    img {
      aspect-ratio: 3/2;
    }

    @media (${breakpoint.lgMin}) {
      flex: 0 0 30%;
    }
  }

  .card-eyebrow,
  .card-title {
    margin-bottom: 8px;
  }

  ${({ $changesDirection }) =>
    $changesDirection &&
    css`
      flex-direction: column;
      @media (${breakpoint.mdMin}) {
        flex-direction: row;
        img {
          aspect-ratio: 1.51/1;
        }
      }
  `};
`;

export const CardSimple = styled(Card)<{ theme: Theme }>`
  padding-bottom: 24px;
  border-bottom: 1px solid ${getColor('borderSecondary')};

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .card-title {
    font-family: ${font.saolText};
    font-size: 20px;
  }

  .excerpt {
    margin-bottom: 12px;
  }

  .card-image {
    img {
      display: none;
    }
  }
  
  .card-title {
    margin-bottom: 8px;
  }
`;

export const CardMd = styled(Card)<{
  theme: Theme;
  $changesDirection?: boolean;
}>`
  flex-direction: row;
  gap: 16px;

  .card-image {
    flex: 0 0 30%;
    img {
      aspect-ratio: 1.51/1;
    }
  }

  .card-title {
    margin-bottom: 8px;
  }

  ${({ $changesDirection }) =>
    $changesDirection &&
    css`
      flex-direction: column;
      .card-image {
        width: 50%;
        img {
          aspect-ratio: 3/2;
        }
      }
      @media (${breakpoint.mdMin}) {
        flex-direction: row;
        .card-image {
          flex: 0 0 30%;
          img {
            aspect-ratio: 1.51/1;
          }
        }
      }
  `};

  &:has(.excerpt) {
    ${hasExcerptStyles};
  }
`;

export const CardLg = styled(Card)<{
  theme: Theme;
}>`
  flex-direction: column;
  gap: 16px;

  .card-image {
    img {
      aspect-ratio: 3/2;
    }
  }

  .card-title {
    margin-bottom: 16px;
  }

  &:has(.excerpt) {
    ${hasExcerptStyles};

    img {
      aspect-ratio: 16/9;
    }
  }
`;

export const CardXl = styled(Card)<{
  theme: Theme;
}>`
  flex-direction: column;
  gap: 16px;

  .card-title {
    margin-bottom: 16px;
  }

  .card-image {
    img {
      aspect-ratio: 3/2;
    }
  }

  &:has(.excerpt) {
    ${hasExcerptStyles};
  }
`;

export const CardHero = styled(Card)<{
  theme: Theme;
}>`
  flex-direction: column;
  gap: 16px;

  @container cardContainer (min-width: ${bkptVal.sm}rem){
    .card-image {
      margin-bottom: 8px;
    }
  }

  .card-image {
    img {
      aspect-ratio: 3/2;
    }
  }

  .card-eyebrow {
    margin-bottom: 16px;
  }

  .card-title {
    margin-bottom: 16px;
  }
`;
